import React from "react";

const WebDev = () => {
  return (
    <div className='contents webdev'>
      <h4> Web Development</h4>

      <p>
        Adam transitioned from a 25-year international career in the music industry—where he thrived as a performer,
        educator, author, and entrepreneur—into the digital space in 2019. Leveraging both his technical expertise and
        deep artistic insight, he now helps others achieve their digital business goals.
      </p>
      <p>
        {" "}
        Working with a diverse range of high-profile international clients, from solo musicians to major automotive
        brands, Adam has a unique understanding of the modern artist’s need for a strong digital presence. His extensive
        experience in the creative industries gives him an intuitive grasp of artistic vision, audience engagement, and
        brand storytelling, allowing him to craft compelling digital strategies that truly resonate. By blending
        creativity with cutting-edge technology, he helps artists and businesses bring their ideas to life in the
        digital world. With a rare combination of artistic sensibility and technical skill, Adam ensures his clients
        stand out, connect with their audiences, and thrive online.
      </p>

      <p>
        If you'd like to collaborate, or have enquiries about websites, please{" "}
        <a href='mailto:mail@adamwoolf.com'>get in touch.</a>
      </p>
      <a className='button' href='https://www.webspinner.eu'>
        Visit WebSpinner.eu
      </a>
    </div>
  );
};

export default WebDev;
